import { createSlice, createAction } from "@reduxjs/toolkit";

export const fetchItinerary = createAction("itineraries/fetchItinerary");
export const fetchAirlines = createAction("itineraries/fetchAirlines");

const initialState = {
  itineraries: [],
  airlines: [],
  legDescData: [],
  isLoading: false,
  error: null,
};

const itinerarySlice = createSlice({
  name: "itinerary",
  initialState,
  reducers: {
    setItineraries(state, action) {
      state.itineraries = action.payload;
      state.isLoading = false;
    },
    setAirlines(state, action) {
      state.airlines = action.payload;
    },
    setLegDesc(state, action) {
      state.legDescData = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    clearError(state) {
      state.error = null;
      state.isLoading = false;
    },
  },
});

export const {
  setItineraries,
  setAirlines,
  setLegDesc,
  setIsLoading,
  setError,
  clearError,
} = itinerarySlice.actions;

export default itinerarySlice.reducer;
